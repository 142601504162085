import React from "react";
import "./style.scss";

const IvrSection = () => {
  return (
    <div className="ivr-csm">
      <div className="help"> Have more questions? </div>
      <div className="new-underline">
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" loading="lazy" />
      </div>
      <div className="available">Available from 10 AM - 8 PM, Monday-Saturday</div>
      <div className="ivr-body">
        <div className="call">
          <div className="title"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm291gvra.png" className="ivricon" />Call</div>
          <div className="desc mtop-1">+91 7827135490 </div>
        </div>
        <div className="email">
          <div className="title"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a70zk8.png" className="ivricon" /> Email</div>
          <div className="desc"><a href="mailto:contact@kraftshala.com"><u>contact@kraftshala.com</u></a></div>
        </div>
      </div>
    </div>
  );
};

export default IvrSection;
